@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: "Lexend";
    src: url(../public/fonts/Lexend-Thin.woff) format("woff");
    /* other formats include: 'woff2', 'truetype, 'opentype',
                                   'embedded-opentype', and 'svg' */
    font-style: normal;
    font-weight: 100;
    font-display: swap;
 }
 
 @font-face {
    font-family: "Lexend";
    src: url(../public/fonts/Lexend-ExtraLight.woff) format("woff");
    /* other formats include: 'woff2', 'truetype, 'opentype',
                                   'embedded-opentype', and 'svg' */
    font-style: normal;
    font-weight: 200;
    font-display: swap;
 }
 
 @font-face {
    font-family: "Lexend";
    src: url(../public/fonts/Lexend-Regular.woff) format("woff");
    /* other formats include: 'woff2', 'truetype, 'opentype',
                                   'embedded-opentype', and 'svg' */
    font-style: normal;
    font-weight: 400;
    font-display: swap;
 }
 @font-face {
    font-family: "Lexend";
    src: url(../public/fonts/Lexend-Medium.woff) format("woff");
    /* other formats include: 'woff2', 'truetype, 'opentype',
                                   'embedded-opentype', and 'svg' */
    font-style: normal;
    font-weight: 500;
    font-display: swap;
 }
 @font-face {
    font-family: "Lexend";
    src: url(../public/fonts/Lexend-SemiBold.woff) format("woff");
    /* other formats include: 'woff2', 'truetype, 'opentype',
                                   'embedded-opentype', and 'svg' */
    font-style: normal;
    font-weight: 600;
    font-display: swap;
 }
 
 @font-face {
    font-family: "Lexend";
    src: url(../public/fonts/Lexend-Bold.woff) format("woff");
    /* other formats include: 'woff2', 'truetype, 'opentype',
                             'embedded-opentype', and 'svg' */
    font-style: normal;
    font-weight: 700;
    font-display: swap;
 }
 
 @font-face {
    font-family: "Lexend";
    src: url(../public/fonts/Lexend-ExtraBold.woff) format("woff");
    /* other formats include: 'woff2', 'truetype, 'opentype',
                             'embedded-opentype', and 'svg' */
    font-style: normal;
    font-weight: 800;
    font-display: swap;
 }
 
 @font-face {
    font-family: "Lexend";
    src: url(../public/fonts/Lexend-Black.woff) format("woff");
    /* other formats include: 'woff2', 'truetype, 'opentype',
                                'embedded-opentype', and 'svg' */
    font-style: normal;
    font-weight: 900;
    font-display: swap;
 }
 